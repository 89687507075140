<template>
  <v-card width="350">
    <v-form
      @submit.prevent="
        setSanitaryGrid({
          name: nameTarget,
          color: colorTarget,
          company: companyTarget,
          comment: commentTarget,
          begin_date: dateStartTarget,
          end_date: dateEndTarget
        })
      "
    >
      <v-col align="center">
        <h3 class="mt-5 mb-2">Паспорт сетки санитарной обработки</h3>
        <v-row dense>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-card-text class="text-start">Название</v-card-text>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                type="text"
                v-model="nameTarget"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-card-text class="text-start">Цвет</v-card-text>
            </v-col>
            <v-col cols="12" sm="8">
              <v-color-picker
                v-model="colorTarget"
                class="ma-2"
                hide-canvas
                hide-mode-switch
              ></v-color-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-card-text class="text-start"
                >Обрабатывающая компания</v-card-text
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                type="text"
                v-model="companyTarget"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-card-text class="text-start"
                >Дата начала обработки</v-card-text
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu v-model="dateStart">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStartTarget"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateStartTarget" no-title scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-card-text class="text-start">Дата конца обработки</v-card-text>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu v-model="dateEnd">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEndTarget"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateEndTarget" no-title scrollable>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-textarea
            placeholder="Комментарий"
            v-model="commentTarget"
            class="pa-4"
            rows="2"
            :rules="[
              v =>
                (v || '').length <= 250 ||
                'Описание должно содержать не более 250 символов'
            ]"
          ></v-textarea>
        </v-row>
        <div class="mt-2">
          <v-row dense justify="center">
            <v-col cols="12" sm="6">
              <v-card-text class="text-center">
                <v-btn type="submit" class="font-weight-bold">Сохранить</v-btn>
              </v-card-text>
            </v-col>
            <v-col v-if="!isDraw" cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  v-on:click="$emit('remove-sanitary')"
                  >Удалить</v-btn
                ></v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "SanitaryGridPassport",

  props: { properties: Object, setSanitaryGrid: Function, isDraw: Boolean },

  data() {
    return {
      nameTarget: null,
      colorTarget: null,
      companyTarget: null,
      dateStart: false,
      dateStartTarget: null,
      dateEnd: false,
      dateEndTarget: null,
      commentTarget: ""
    };
  },

  watch: {
    properties: function(newValue) {
      this.nameTarget = newValue.name;
      this.colorTarget = newValue.color;
      this.companyTarget = newValue.company;
      this.commentTarget = newValue.comment;
      this.dateStartTarget = newValue.begin_date;
      this.dateEndTarget = newValue.end_date;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--dark.v-color-picker .v-color-picker__input input {
  color: white;
}

::v-deep .v-input__slot {
  margin: 0;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
